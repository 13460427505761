<template>
  <div v-if="payload.visible" class="search-hints">
    <div class="form">
      <i class="icon-ssearch" />
      <input
        id="ssearch"
        ref="ssearch"
        v-model="searchQuery"
        :class="{ active: searchQuery }"
        @input="trottled"
        @focus="focused = true"
        @blur="focused = false"
        type="search"
        name="ssearch"
        autocomplete="off"
        placeholder="Начните вводить"
      >
      <div v-if="!searchQuery.length && payload.popular.length" class="popular-queries">
        <strong>Популярные запросы</strong>
        <ul>
          <li v-for="item in payload.popular" :key="`sh-popular-${item.title}`">
            <nuxt-link :to="item.link">{{ item.title }}</nuxt-link>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="searchQuery.length > 2" class="datalist">
      <div v-if="!searchHints.length" class="no-match">
        <div>Ничего не найдено</div>
        <div>
          <nuxt-link to="/random">Рандомный фильм</nuxt-link>
        </div>
      </div>
      <ul v-else>
        <!-- <li>
          <i />
          <span>Возможно вы искали</span>
        </li> -->
        <nuxt-link
          v-for="item in searchHints"
          :to="`/${item.type}/${
            item.type === 'film'
              ? postUrl(item.id, item.title)
              : postUrl(item.id, item.title)
          }`"
          :key="`sh-${item.id}`"
          tag="li"
        >
          <div class="poster">
            <img :src="item.poster" :alt="item.title">
          </div>
          <p>
            <strong>{{ item.title }}</strong>
            <span v-if="item.year">{{ item.year }}</span>
            <span v-if="item.genres"> {{ item.genres.join(', ') }}</span>
          </p>
        </nuxt-link>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    payload: {
      type: Object,
      require: true,
      default: () => ({
        visible: false, type: 'db', popular: [], text: ''
      })
    }
  },
  watch: {
    route() {
      this.searchQuery = ''
      this.searchHints = []
    }
  },
  computed: {
    route() {
      return this.$route.fullPath
    }
  },
  data() {
    return {
      searchQuery: '',
      searchHints: []
    }
  },
  mounted() {
    if (this.payload.text) {
      this.searchQuery = this.payload.text
      this.getSearchHints()
    }
  },
  methods: {
    trottled() {
      if (!this.searchQuery.trim()) return
      let oldValue = ''
      setTimeout(() => {
        if (
          oldValue === this.searchQuery &&
          this.searchQuery !== '' &&
          this.searchQuery.length > 2
        ) {
          this.getSearchHints()
        }
      }, 200)
      oldValue = this.searchQuery
    },
    async getSearchHints() {
      const url = `/api/searchhints?value=${encodeURIComponent(this.searchQuery)}&type=${this.payload.type}`
      const { data } = await this.$axios.get(url)
      if (data.error) {
        this.$store.commit('setError', { status: 'warn', msg: data.error.message })
        setTimeout(() => this.$store.commit('clearError'), 2000)
      } else {
        this.searchHints = data
      }
    }
  }
}
</script>
